import {Injectable} from '@angular/core';
import {Severity} from 'src/app/enums/core/severity.enum';
import {TypedObject} from 'src/app/interfaces/core/typed-object.interface';

@Injectable({providedIn: 'root'})
export class ConfirmDialogService {
    public fn: (() => void) | null = null;
    public visible: boolean = false;
    public header: string = '';
    public bodyContent: string = '';
    public confirmButtonSeverity: Severity = Severity.PRIMARY;

    public open(header: string, bodyContent: string, fn: (() => void), options?: {
        translationParameters?: TypedObject<string>
        confirmButtonSeverity?: Severity
    }) {
        this.header = header;
        this.bodyContent = bodyContent;
        this.fn = fn;
        this.visible = true;
        this.confirmButtonSeverity = options?.confirmButtonSeverity ?? Severity.PRIMARY;
    }

    public onConfirm() {
        this.fn?.();
    }
}